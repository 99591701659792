export const meetsLength = function (password, min, max) {
  min = min || -1
  max = max || -1

  const count = password.length

  return (
    (min === -1 ? true : count >= min) && (max === -1 ? true : count <= max)
  )
}

export const meetsUpperCase = function (password, min, max) {
  min = min || -1
  max = max || -1

  const count = (password.match(/^(?=.*[A-Z]).+$/) || []).length
  return (
    (min === -1 ? true : count >= min) && (max === -1 ? true : count <= max)
  )
}

export const meetsLowerCase = function (password, min, max) {
  min = min || -1
  max = max || -1

  const count = (password.match(/^(?=.*[a-z]).+$/) || []).length
  return (
    (min === -1 ? true : count >= min) && (max === -1 ? true : count <= max)
  )
}

export const meetsNumber = function (password, min, max) {
  min = min || -1
  max = max || -1

  const count = (password.match(/^(?=.*[0-9]).+$/) || []).length
  return (
    (min === -1 ? true : count >= min) && (max === -1 ? true : count <= max)
  )
}

export const meetsSpecial = function (password, min, max) {
  min = min || -1
  max = max || -1

  const count = (password.match(/^(?=.*[\W]).+$/) || []).length
  return (
    (min === -1 ? true : count >= min) && (max === -1 ? true : count <= max)
  )
}

const passwordSecurity = {
  meetsLength,
  meetsLowerCase,
  meetsUpperCase,
  meetsNumber,
  meetsSpecial
}

export default passwordSecurity
