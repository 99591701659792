import { useState } from 'preact/hooks'
import { Router, route } from 'preact-router'

import { Icon, Layout, Navigation } from './components/mdl'

import { AuthContext, FormContext } from './contexts'

import Home from './routes/home'
import Login from './routes/auth/login'
import Registration from './routes/auth/registration'
import SetPassword from './routes/auth/password-confirm'
import SetPaswordRequest from './routes/auth/password-request'
import Settings from './routes/auth/settings'

import Form from './routes/form'

import formBuilderClient from './components/form-builder'
import Case from './routes/case'
import Review from './routes/review'

export default function App () {
  const [auth, setAuth] = useState({})
  const authContext = { auth, setAuth }

  const [forms, setForms] = useState([])
  const formContext = { forms, setForms }

  const onTitleClick = () => {
    route('/')
  }

  // Function to close the drawer
  const closeDrawer = e => {
    // Remove the "is-visible" class from elements with class "mdl-layout__drawer"
    const drawerElements = document.querySelectorAll('.mdl-layout__drawer')
    for (let i = 0; i < drawerElements.length; i++) {
      drawerElements[i].classList.remove('is-visible')
    }

    // Remove the "is-visible" class from elements with class "mdl-layout__obfuscator"
    const obfuscatorElements = document.querySelectorAll(
      '.mdl-layout__obfuscator'
    )
    for (let j = 0; j < obfuscatorElements.length; j++) {
      obfuscatorElements[j].classList.remove('is-visible')
    }
  }
  const onDrawerClose = e => {
    e.preventDefault()
    closeDrawer(e)
  }

  const onSettingsClicked = e => {
    route('/settings')
    onDrawerClose(e)
  }

  const onLogoutClicked = e => {
    closeDrawer(e)
    formBuilderClient.logout(authContext)
    e && e.preventDefault()
    route('/')
  }

  return (
    <AuthContext.Provider value={authContext}>
      <FormContext.Provider value={formContext}>
        <Layout
          fixed-header
          fixed-drawer={false}
          className={`${
            formBuilderClient.isLoggedIn(authContext)
              ? 'is-logged-in'
              : 'is-logged-out'
          }`}
        >
          <Layout.Header>
            <Layout.HeaderRow class='malengo-header-row'>
              <Layout.Title onClick={onTitleClick} style='cursor:pointer'>
                <div class='malengo-header-title'>
                  <img
                    src='/assets/malengo-logo-inverse.png'
                    class='malengo-header-icon'
                  />
                </div>
              </Layout.Title>
              <Layout.Spacer />
              {formBuilderClient.isLoggedIn(authContext) && (
                <Navigation>
                  <Navigation.Link href='' onClick={() => route('/case')}>
                    <Icon>contact_support</Icon> <span>Contact us</span>
                  </Navigation.Link>
                  <Navigation.Link href='/settings' onClick={onSettingsClicked}>
                    <Icon>settings</Icon> <span>Settings</span>
                  </Navigation.Link>
                  <Navigation.Link
                    href=''
                    onClick={onLogoutClicked}
                    className='mobile-menu-close'
                  >
                    <Icon>logout</Icon> <span>Logout</span>
                  </Navigation.Link>
                </Navigation>
              )}
            </Layout.HeaderRow>
          </Layout.Header>
          <Layout.Content>
            <div id='app'>
              <Router>
                <Home
                  path='/'
                  onFormClicked={d => {
                    route(`/form?fid=${d}`)
                  }}
                />

                <Login path='/login' />
                <Registration path='/register' />
                <SetPassword path='/set-password/confirm' />
                <SetPaswordRequest path='/set-password/request' />
                <Settings path='/settings' />

                <Form path='/form' />
                <Case path='/case' />
                <Review path='/review' />
              </Router>
            </div>
          </Layout.Content>

          <Layout.Drawer title='Title'>
            <Navigation>
              {formBuilderClient.isLoggedIn(authContext) && (
                <>
                  <Navigation.Link
                    href=''
                    onClick={e => {
                      route('/case')
                      closeDrawer(e)
                      e.preventDefault()
                    }}
                  >
                    <Icon>contact_support</Icon> <span>Contact us</span>
                  </Navigation.Link>
                  <Navigation.Link href='/settings' onClick={onSettingsClicked}>
                    <Icon>settings</Icon> <span>Settings</span>
                  </Navigation.Link>
                  <Navigation.Link href='' onClick={onLogoutClicked}>
                    <Icon>logout</Icon> <span>Logout</span>
                  </Navigation.Link>
                  <Navigation.Link
                    href=''
                    onClick={onDrawerClose}
                    data-close-drawer='close-icon'
                  >
                    <Icon>close</Icon> <span>Close Menu</span>
                  </Navigation.Link>
                </>
              )}
            </Navigation>
          </Layout.Drawer>
        </Layout>
      </FormContext.Provider>
    </AuthContext.Provider>
  )
}
