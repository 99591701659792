export function showConfirmationDialog () {
  return new Promise((resolve) => {
    // Create modal elements
    const modal = document.createElement('div')
    modal.style.display = 'none'
    modal.style.position = 'fixed'
    modal.style.zIndex = '1'
    modal.style.left = '0'
    modal.style.top = '0'
    modal.style.width = '100%'
    modal.style.height = '100%'
    modal.style.overflow = 'auto'
    modal.style.backgroundColor = 'rgba(0,0,0,0.4)'
    modal.style.paddingTop = '60px'

    const modalContent = document.createElement('div')
    modalContent.style.backgroundColor = '#fefefe'
    modalContent.style.margin = '5% auto'
    modalContent.style.padding = '20px'
    modalContent.style.border = '1px solid #888'
    modalContent.style.width = '80%'
    modalContent.style.maxWidth = '50%'

    const modalText = document.createElement('p')
    modalText.innerText = 'Have you reviewed your answers to ensure that all responses are correct?'
    modalContent.appendChild(modalText)

    const modalFooter = document.createElement('div')
    modalFooter.style.display = 'flex'
    modalFooter.style.justifyContent = 'space-between'

    const btnYes = document.createElement('button')
    btnYes.innerText = 'Yes'
    btnYes.style.padding = '10px 20px'
    btnYes.style.backgroundColor = '#4CAF50'
    btnYes.style.color = 'white'
    btnYes.style.border = 'none'
    btnYes.style.cursor = 'pointer'
    btnYes.onclick = () => {
      document.body.removeChild(modal)
      resolve(true)
    }

    const btnNo = document.createElement('button')
    btnNo.innerText = 'No'
    btnNo.style.padding = '10px 20px'
    btnNo.style.backgroundColor = '#f44336'
    btnNo.style.color = 'white'
    btnNo.style.border = 'none'
    btnNo.style.cursor = 'pointer'
    btnNo.onclick = () => {
      document.body.removeChild(modal)
      resolve(false)
    }

    modalFooter.appendChild(btnYes)
    modalFooter.appendChild(btnNo)
    modalContent.appendChild(modalFooter)
    modal.appendChild(modalContent)
    document.body.appendChild(modal)

    // Display the modal
    modal.style.display = 'block'
  })
}
