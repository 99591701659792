import { route } from 'preact-router'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'

import { Button, Card, Chip, Grid, Icon, TextField, Snackbar } from '../../components/mdl'

// Phone number component
import FieldPhoneRegistration from '../form/field-phone-registration'

import formBuilderClient from '../../components/form-builder'

import { AuthContext } from '../../contexts'

import passwordSecurity from '../../validations/password.js'

export default function Settings (props) {
  const authContext = useContext(AuthContext)
  const [successfulPasswordUpdate, setSuccessfulPasswordUpdate] = useState('')
  const [user, setUser] = useState(formBuilderClient.user)
  const [error, setError] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [formData, setFormData] = useState({
    username: undefined,
    password: undefined,
    password_confirmation: undefined,
    email: undefined,
    phone: undefined
  })

  const passwordCriteriaMatch = {
    criteria_1: {
      label: 'must be between 8 and 64 characters long',
      matches: (password) => {
        return passwordSecurity.meetsLength(password, 8, 64)
      }
    },
    criteria_2: {
      label: 'must contain at least 1 upper case letter (A..Z)',
      matches: (password) => {
        return passwordSecurity.meetsUpperCase(password, 1)
      }
    },
    criteria_3: {
      label: 'must contain at least 1 lower case letter (a..z)',
      matches: (password) => {
        return passwordSecurity.meetsLowerCase(password, 1)
      }
    },
    criteria_4: {
      label: 'must contain at least 1 number (0..9)',
      matches: (password) => {
        return passwordSecurity.meetsNumber(password, 1)
      }
    },
    criteria_5: {
      label: 'must contain at least 1 special character (!..$)',
      matches: (password) => {
        return passwordSecurity.meetsSpecial(password, 1)
      }
    }
  }

  const formRef = {
    username: useRef(),
    password: useRef(),
    password_confirmation: useRef(),
    email: useRef(),
    phone: useRef()
  }

  // On submitting the registration form
  const onSubmit = () => {
    formData.username = formRef.username.current.getValue().value
    formData.password = formRef.password.current.getValue().value
    formData.password_confirmation =
      formRef.password_confirmation.current.getValue().value
    formData.email = formRef.email.current.getValue().value
    // formData.phone = formRef.phone.current.getValue().value

    if (typeof formRef?.phone?.current !== 'undefined') {
      formData.phone = formRef.phone.current.props.value
    }

    if (formData.password !== formData.password_confirmation) {
      formRef.password_confirmation.current.setCustomError(
        'Password confirmation does not match.'
      )
    }

    if (!formData.email) {
      formRef.email.current.setCustomError(
        'A valid Email address must be provided.'
      )
    }

    const validPassword =
      passwordSecurity.meetsLength(formData.password, 8, 64) &&
      passwordSecurity.meetsUpperCase(formData.password, 1) &&
      passwordSecurity.meetsLowerCase(formData.password, 1) &&
      passwordSecurity.meetsNumber(formData.password, 1) &&
      passwordSecurity.meetsSpecial(formData.password, 1)

    if (!validPassword) {
      formRef.password.current.setCustomError(
        "Password doesn't meet the required criteria"
      )
    }

    const allIn =
      formData.username &&
      formData.password &&
      formData.password === formData.password_confirmation &&
      formData.email &&
      validPassword

    if (allIn) {
      setFormData(formData)
      formBuilderClient
        .changePassword(formData)
        .then(rc => {
          setSuccessfulPasswordUpdate(rc.message)
          window.setTimeout(function () {
            setSuccessfulPasswordUpdate('')
          }, 2500)

          setFormData({ ...formData, password: '', password_confirmation: '' })
        })
        .catch(err => {
          setError(err)
        })
    }
  }

  const handleFormKeypress = e => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  if (!user) {
    formBuilderClient
      .getUser()
      .then(rc => {
        formBuilderClient.user = rc
        setUser(rc)
        console.log(rc)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const togglePasswordVisibility = () => {
    // Reset the form data
    const formData2 = {}
    formData2.username = formRef.username.current.getValue().value
    formData2.password = formRef.password.current.getValue().value
    formData2.password_confirmation =
        formRef.password_confirmation.current.getValue().value
    formData2.email = formRef.email.current.getValue().value

    if (typeof formRef?.phone?.current !== 'undefined') {
      formData2.phone = formRef.phone.current.props.value
    }

    setFormData(formData2)
    setShowPassword(!showPassword)
  }

  const handlePasswordEntered = e => {
    // const typedPassword = e.target.value
    // console.log( typedPassword )
    // setEnteredPassword( typedPassword )
    // console.log( e.target.value )
    const formData2 = {}
    formData2.username = formRef.username.current.getValue().value
    formData2.password = formRef.password.current.getValue().value
    formData2.password_confirmation =
        formRef.password_confirmation.current.getValue().value
    formData2.email = formRef.email.current.getValue().value

    if (typeof formRef?.phone?.current !== 'undefined') {
      formData2.phone = formRef.phone.current.props.value
    }

    setFormData(formData2)
  }

  useEffect(() => {
    if (error.field) {
      formRef[error.field].current.setCustomError(error.message)
    }
  }, [error])

  const passwordToggleButton = () => {
    return (
      <a
        href='#'
        className='password-show'
        onClick={togglePasswordVisibility}
      >
        <i class='material-icons'>
          {showPassword ? 'visibility' : 'visibility_off'}
        </i>
      </a>
    )
  }

  // If not logged in send to login page
  if (!formBuilderClient.isLoggedIn(authContext)) {
    route(`/login?next=${encodeURIComponent('/settings')}`)
  }

  return (
    <>
      <Card id='registration-card' class='auth-card'>
        <Card.Title class='mdl-card--border'>
          <Card.TitleText>Settings</Card.TitleText>
        </Card.Title>
        <Card.Text class='mdl-card--border'>
          <Grid class='form--1-col'>
            <Grid.Cell>
              <p>Your information</p>
            </Grid.Cell>
            <Grid.Cell>
              <TextField
                ref={formRef.username}
                required
                floating-label
                label='Username'
                type='text'
                name='username'
                value={user?.username}
                disabled
              />
            </Grid.Cell>
            <Grid.Cell>
              <TextField
                ref={formRef.email}
                required
                floating-label
                label='Email'
                type='email'
                name='email'
                value={user?.email}
                disabled
              />
            </Grid.Cell>
            <Grid.Cell>
              {/* <PhoneInput
                name='phone'
                ref={formRef.phone}
                value={phoneValue}
                country='us'
                onChange={onPhoneValueChange}
                enableSearch
                isValid={isValidPhone}
              />
              {validationMessage && <p style={{ color: 'red', fontWeight: 'bold' }}>{validationMessage}</p>} */}

              {user?.phone && (
                <FieldPhoneRegistration
                  name='phone'
                  ref={formRef.phone}
                  value={user?.phone}
                />
              )}
            </Grid.Cell>
            <Grid.Cell>
              <p>Set a new password: </p>
            </Grid.Cell>
            <Grid.Cell>
              <div className='mdl-password-wrapper'>
                <TextField
                  ref={formRef.password}
                  required
                  floating-label
                  label='New Password'
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  value={formData.password}
                  onKeyUp={e => handlePasswordEntered(e)}
                />
                {passwordToggleButton()}
              </div>
            </Grid.Cell>
            <Grid.Cell>
              <div className='mdl-password-wrapper'>
                <TextField
                  ref={formRef.password_confirmation}
                  required
                  floating-label
                  label='Confirm Password'
                  type={showPassword ? 'text' : 'password'}
                  name='password_confirmation'
                  value={formData.password_confirmation}
                  onKeyPress={e => handleFormKeypress(e)}
                />
                {passwordToggleButton()}
              </div>
            </Grid.Cell>
            <Grid.Cell>
              <div className='password-requirement-list'>
                <p>Password requirements:</p>
                <ul className='pw-checklist'>
                  {Object.keys(passwordCriteriaMatch).map(criteriaKey => {
                    const criteria = passwordCriteriaMatch[criteriaKey]
                    const pw = formData.password ? formData.password : ''
                    const matches = criteria.matches(pw)

                    return (
                      <li key={criteriaKey} className={matches ? 'matches' : ''}>
                        {criteria.label}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </Grid.Cell>
          </Grid>
          {error.message && !error.field && (
            <Chip
              contact-class='mdl-color--accent mdl-color-text--white'
              contact={<Icon icon='error-outline' />}
              text={error.message}
            />
          )}
        </Card.Text>
        <Card.Actions class='mdl-dialog__actions'>
          <Button raised primary onClick={onSubmit}>
            UPDATE PASSWORD
          </Button>
        </Card.Actions>
      </Card>

      <Snackbar active={!!successfulPasswordUpdate.length}>
        <p>{successfulPasswordUpdate}</p>
      </Snackbar>
    </>
  )
}
