import { route } from 'preact-router'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'

import { Button, Card, Chip, Grid, Icon, TextField } from '../../components/mdl'

// Phone number component
import FieldPhoneRegistration from '../form/field-phone-registration'

import formBuilderClient from '../../components/form-builder'

import { AuthContext } from '../../contexts'

import passwordSecurity from '../../validations/password.js'
import Anonymous from '../../components/anonymous'

// Add the arrowStyle object for the email guidlines message
const arrowStyles = {
  arrowBox: {
    position: 'relative',
    background: '#4f82a9',
    border: '4px solid #4f82a9',
    display: 'inline-block',
    padding: '7px' // Adjust padding as needed
  },
  arrowAfter: {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '100%',
    borderWidth: '30px',
    borderColor: 'transparent transparent transparent #4f82a9',
    borderStyle: 'solid',
    borderLeftColor: '#4f82a9',
    transform: 'translateY(-50%)'
  },
  arrowBefore: {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '100%',
    borderWidth: '36px',
    borderColor: 'transparent transparent transparent #4f82a9',
    borderStyle: 'solid',
    borderLeftColor: '#4f82a9',
    transform: 'translateY(-50%)'
  }
}

// ArrowComponent definition
const ArrowComponent = () => (
  <div style={arrowStyles.arrowBox}>
    <div style={arrowStyles.arrowAfter} />
    <div style={arrowStyles.arrowBefore} />
  </div>
)

export default function Registration (props) {
  const authContext = useContext(AuthContext)

  const [error, setError] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [showGuidance, setShowGuidance] = useState(false) // State for showing guidance
  const [formData, setFormData] = useState({
    username: undefined,
    password: undefined,
    password_confirmation: undefined,
    email: undefined,
    phone: undefined
  })
  const toggleGuidance = () => setShowGuidance(!showGuidance) // Toggle guidance visibility
  const passwordCriteriaMatch = {
    criteria_1: {
      label: 'must be between 8 and 64 characters long',
      matches: (password) => {
        return passwordSecurity.meetsLength(password, 8, 64)
      }
    },
    criteria_2: {
      label: 'must contain at least 1 upper case letter (A..Z)',
      matches: (password) => {
        return passwordSecurity.meetsUpperCase(password, 1)
      }
    },
    criteria_3: {
      label: 'must contain at least 1 lower case letter (a..z)',
      matches: (password) => {
        return passwordSecurity.meetsLowerCase(password, 1)
      }
    },
    criteria_4: {
      label: 'must contain at least 1 number (0..9)',
      matches: (password) => {
        return passwordSecurity.meetsNumber(password, 1)
      }
    },
    criteria_5: {
      label: 'must contain at least 1 special character (!..$)',
      matches: (password) => {
        return passwordSecurity.meetsSpecial(password, 1)
      }
    }
  }

  const formRef = {
    username: useRef(),
    password: useRef(),
    password_confirmation: useRef(),
    email: useRef(),
    phone: useRef()
  }

  if (formBuilderClient.isLoggedIn(authContext)) {
    route(props.next || '/')
    return null
  }

  // On submitting the registration form
  const onSubmit = () => {
    // alert('Registration Closed. Please contact admin user.')
    // setError('Registration Closed. Please contact admin user.')
    formData.username = formRef.username.current.getValue().value
    formData.password = formRef.password.current.getValue().value
    formData.password_confirmation =
      formRef.password_confirmation.current.getValue().value
    formData.email = formRef.email.current.getValue().value
    // formData.phone = formRef.phone.current.getValue().value
    formData.phone = formRef.phone.current.props.value

    if (formData.password !== formData.password_confirmation) {
      formRef.password_confirmation.current.setCustomError(
        'Password confirmation does not match.'
      )
    }

    if (!formData.email) {
      formRef.email.current.setCustomError(
        'A valid Email address must be provided.'
      )
    }

    const validPassword =
      passwordSecurity.meetsLength(formData.password, 8, 64) &&
      passwordSecurity.meetsUpperCase(formData.password, 1) &&
      passwordSecurity.meetsLowerCase(formData.password, 1) &&
      passwordSecurity.meetsNumber(formData.password, 1) &&
      passwordSecurity.meetsSpecial(formData.password, 1)

    if (!validPassword) {
      formRef.password.current.setCustomError(
        "Password doesn't meet the required criteria"
      )
    }

    const allIn =
      formData.username &&
      formData.password &&
      formData.password === formData.password_confirmation &&
      formData.email &&
      validPassword

    if (allIn) {
      setFormData(formData)
      formBuilderClient
        .register(formData)
        .then(rc => {
          formBuilderClient
            .login(formData.username, formData.password, authContext)
            .then(_ => {
              route(props.next || '/')
            })
            .catch(err => {
              console.log(err) // This should never happen.
              route('/login')
            })
        })
        .catch(err => {
          setError(err)
        })
    }
  }

  const handleFormKeypress = e => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const onLogin = () => {
    route('/login')
  }

  const togglePasswordVisibility = () => {
    // Reset the form data
    const formData2 = {}
    formData2.username = formRef.username.current.getValue().value
    formData2.password = formRef.password.current.getValue().value
    formData2.password_confirmation =
        formRef.password_confirmation.current.getValue().value
    formData2.email = formRef.email.current.getValue().value
    formData2.phone = formRef.phone.current.props.value
    setFormData(formData2)
    setShowPassword(!showPassword)
  }

  const handlePasswordEntered = e => {
    // const typedPassword = e.target.value
    // console.log( typedPassword )
    // setEnteredPassword( typedPassword )
    // console.log( e.target.value )
    const formData2 = {}
    formData2.username = formRef.username.current.getValue().value
    formData2.password = formRef.password.current.getValue().value
    formData2.password_confirmation =
        formRef.password_confirmation.current.getValue().value
    formData2.email = formRef.email.current.getValue().value
    formData2.phone = formRef.phone.current.props.value

    setFormData(formData2)
  }

  useEffect(() => {
    if (error.field) {
      formRef[error.field].current.setCustomError(error.message)
    }
  }, [error])

  const passwordToggleButton = () => {
    return (
      <a
        href='#'
        className='password-show'
        onClick={togglePasswordVisibility}
      >
        <i class='material-icons'>
          {showPassword ? 'visibility' : 'visibility_off'}
        </i>
      </a>
    )
  }

  return (
    <Anonymous>
      {!showGuidance && (
        <Card id='registration-card' class='auth-card'>
          <Card.Title class='mdl-card--border'>
            <Card.TitleText>Register</Card.TitleText>
          </Card.Title>
          <Card.Text class='mdl-card--border'>
            <Grid class='form--1-col'>
              <Grid.Cell>
                {/* <p style={{ color: 'red' }}>
                  Applications for the 2024–2025 intake are now closed, and registration is closed.
                </p> */}
                <p>Please make sure to provide a valid email address and phone number to complete the verification process.</p>
              </Grid.Cell>
              <Grid.Cell>
                <TextField
                  ref={formRef.username}
                  required
                  floating-label
                  label='Username'
                  type='text'
                  name='username'
                  value={formData.username}
                />
              </Grid.Cell>
              <Grid.Cell>
                <TextField
                  ref={formRef.email}
                  required
                  floating-label
                  label='Email'
                  type='email'
                  name='email'
                  value={formData.email}
                />
              </Grid.Cell>
              <Grid.Cell>
                {/* <PhoneInput
                  name='phone'
                  ref={formRef.phone}
                  value={phoneValue}
                  country='us'
                  onChange={onPhoneValueChange}
                  enableSearch
                  isValid={isValidPhone}
                />
                {validationMessage && <p style={{ color: 'red', fontWeight: 'bold' }}>{validationMessage}</p>} */}

                <FieldPhoneRegistration
                  name='phone'
                  ref={formRef.phone}
                  value={formData.phone}
                />
              </Grid.Cell>
              <Grid.Cell>
                <div className='mdl-password-wrapper'>
                  <TextField
                    ref={formRef.password}
                    required
                    floating-label
                    label='Password'
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    value={formData.password}
                    onKeyUp={e => handlePasswordEntered(e)}
                  />
                  {passwordToggleButton()}
                </div>
              </Grid.Cell>
              <Grid.Cell>
                <div className='mdl-password-wrapper'>
                  <TextField
                    ref={formRef.password_confirmation}
                    required
                    floating-label
                    label='Confirm Password'
                    type={showPassword ? 'text' : 'password'}
                    name='password_confirmation'
                    value={formData.password_confirmation}
                    onKeyPress={e => handleFormKeypress(e)}
                  />
                  {passwordToggleButton()}
                </div>
              </Grid.Cell>
              <Grid.Cell>
                <div className='password-requirement-list'>
                  <p>Password requirements:</p>
                  <ul className='pw-checklist'>
                    {Object.keys(passwordCriteriaMatch).map(criteriaKey => {
                      const criteria = passwordCriteriaMatch[criteriaKey]
                      const pw = formData.password ? formData.password : ''
                      const matches = criteria.matches(pw)

                      return (
                        <li key={criteriaKey} className={matches ? 'matches' : ''}>
                          {criteria.label}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </Grid.Cell>
            </Grid>
            {error.message && !error.field && (
              <Chip
                contact-class='mdl-color--accent mdl-color-text--white'
                contact={<Icon icon='error-outline' />}
                text={error.message}
              />
            )}
          </Card.Text>
          <Card.Actions class='mdl-dialog__actions'>
            <Button raised primary onClick={onSubmit}>
              Submit
            </Button>
          </Card.Actions>
        </Card>
      )}
      <div class='auth-text'>
        Already registered?&nbsp;
        <a href='' onClick={onLogin}>Login</a>
      </div>
      <div className='frame-content'>
        {/* Guidance text */}
        <p className='paragraph-content'>
          Need some guidance on how to create an <br /> email address? Check out the steps below{' '}
          {!showGuidance && (
            <a href='#' onClick={toggleGuidance} className='paragraph-body'>
              <ArrowComponent />
            </a>
          )}
        </p>
        {/* Conditional rendering of the guidance content */}
        {showGuidance && (
          <div className='guidance-content'>
            <p><strong>1. Go to Gmail:</strong></p>
            <p>&emsp;1. Open your web browser and go to the Gmail website by typing "gmail.com" in the address bar and pressing Enter.</p>

            <p><strong>2. Create an Account:</strong></p>
            <p>&emsp;1. Click on the "Create account" button.</p>
            <p>&emsp;2. Choose "For myself" from the dropdown menu.</p>

            <p><strong>3. Fill in Your Information:</strong></p>
            <p>&emsp;1. Enter your first name and last name.</p>
            <p>&emsp;2. Choose a username for your email address. It’s best to use a combination of your first and last name. For example, if your name is John Doe, you might use "john.doe" as your email address. NOTE: this is just an example, please do not use this email address.</p>
            <p>&emsp;3. Create a strong password. Make sure it’s something you can remember, but also save it in a secure place.</p>

            <p><strong>4. Verify Your Account:</strong></p>
            <p>&emsp;1. Follow the prompts to verify your account. This may involve providing a phone number and entering a verification code sent to you.</p>

            <p><strong>5. Complete Your Profile:</strong></p>
            <p>&emsp;1. Enter any additional requested information, such as a recovery email address and your date of birth.</p>

            <p><strong>6. Agree to the Terms:</strong></p>
            <p>&emsp;1. Read through Google's terms of service and privacy policy, then click "I agree" to create your account.</p>

            <p><strong>Malengo’s Recommendations:</strong></p>
            <p><strong>&emsp;1. Save Your Password:</strong></p>
            <p>&emsp;&emsp;Make sure to save your password in a secure place or use a password manager. It’s crucial to have a password that you can remember but is also secure.</p>

            <p><strong>&emsp;2. Check Your Email Regularly:</strong></p>
            <p>&emsp;&emsp;It is important to check your email regularly. This is the primary way we will contact you, so staying on top of your inbox ensures you don’t miss any important messages.</p>

            {/* Button to close the guidance content */}
            <div style={{ textAlign: 'right' }}>
              <Button
                style={{ backgroundColor: 'red', color: 'white' }}
                onClick={toggleGuidance}
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </div>
    </Anonymous>
  )
}
