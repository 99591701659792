import { useRef, useState } from 'preact/hooks'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import phoneValidations from '../../validations/phone.js'

export default function FieldPhoneRegistration (props) {
  const ref = useRef()
  // const value = props.value
  // For mobile phone component
  const [phoneValue, setPhoneValue] = useState(props.value)
  const [validationMessage, setValidationMessage] = useState('')

  /*
  const onDataChanged = event => {
    if (props.onDataChanged) {
      props.onDataChanged({ [field.name]: field.getValue() })
    }
  }
  */

  const onPhoneValueChange = val => {
    if (!val.startsWith('+')) {
      val = `+${val}`
    }

    setPhoneValue(val)
    if (val) {
      props.value = val
    }
  }

  const isValidPhone = (val, country) => {
    let finalValidity = true
    const fullPhoneNumber = val

    // Check its validity
    const checkValidity = phoneValidations.isPhoneNumberValid(fullPhoneNumber, country)
    if (!checkValidity.valid && val !== country.dialCode) {
      setValidationMessage('Please enter a valid phone number.')
      finalValidity = checkValidity.valid
    } else {
      setValidationMessage('')
      finalValidity = true
    }

    return finalValidity
  }

  return (
    <>
      <PhoneInput
        // value={ value }
        ref={ref}
        value={phoneValue}
        country='ug'
        onChange={onPhoneValueChange}
        enableSearch
        isValid={isValidPhone}
      />
      {validationMessage && <p style={{ color: 'red', fontWeight: 'bold' }}>{validationMessage}</p>}
    </>
  )
}
