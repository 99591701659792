import { isValidPhoneNumber } from 'libphonenumber-js'

// Phone number validations
export const isPhoneNumberValid = function (fullPhoneNumber, country) {
  // function expects
  // fullPhoneNumber: Phone number with country code, without +
  // country: data from the phone input component regarding the current selection
  const result = {
    valid: false,
    data: null,
    message: ''
  }

  // Check if the phone number is valid or not
  const countryIso2 = country.iso2.toUpperCase()
  const isValid = isValidPhoneNumber(fullPhoneNumber, countryIso2)
  result.valid = isValid
  result.message = isValid ? 'Phone number is valid' : 'Phone number is not valid, please check again'
  result.data = {
    country,
    countryIso2,
    phoneNumber: fullPhoneNumber
  }

  return result
}

export const isValidPhoneNumberWithCountry = function (phoneNumber, countryCode, countryName) {
  // console.log(countryCode)
  // Define regular expressions for phone    number formats in supported countries
  const phoneRegexMap = {
    us: /^\d{10,15}$/, // United States
    uk: /^\d{10,15}$/, // United Kingdom
    fr: /^\d{9,15}$/, // France
    de: /^\d{10,15}$/ // Germany
    // Add more country code regex patterns as needed
  }

  // Default regex to check if the phone number has 10 to 15 digits
  const defaultRegex = /^\d{10,15}$/

  // Check if the provided country code exists in the map
  if (countryCode in phoneRegexMap) {
    const phoneRegex = phoneRegexMap[countryCode]
    // Validate the phone number format for the specified country
    if (phoneRegex.test(phoneNumber)) {
      return true
    }
  } else {
    // Use the default regex for unsupported country codes
    if (defaultRegex.test(phoneNumber)) {
      // console.log('Testing default regex : ', countryCode)
      return true
    }
  }

  return false
}

export default { isValidPhoneNumberWithCountry, isPhoneNumberValid }
