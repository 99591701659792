function getStorage () {
  return localStorage // eslint-disable-line no-undef
}

export function storageLoad (key, fallback = {}) {
  const storage = getStorage()

  return (storage[key] && JSON.parse(storage[key])) || fallback
}

export function storageSave (key, data, merge = false) {
  if (merge) {
    const local = storageLoad(key)
    data = { ...local, ...data }
  }

  getStorage()[key] = JSON.stringify(data)
}

export default { storageSave, storageLoad }
