import { Component } from 'preact'
import { extend } from '../utils'

export const options = {}

function mdl () {
  return options.mdl || options.componentHandler || window.componentHandler
}

const RIPPLE_CLASS = 'js-ripple-effect'

const MDL_PREFIX = s => (MDL_NO_PREFIX[s] ? s : `mdl-${s}`)

const MDL_NO_PREFIX = { 'is-active': true }

let uidCounter = 1

function uid () {
  return ++uidCounter
}

function setClass (props, value, append) {
  const cl = getClass(props)
  if (props.className) delete props.className
  if (append) value = cl ? `${cl} ${value}` : value
  props.class = value
}

function getClass (props) {
  return props?.class || props?.className
}

const propMaps = {
  disabled ({ props }) {
    if (Object.hasOwnProperty.call(props, 'disabled') && !props.disabled) {
      props.disabled = null
    }
  },
  badge ({ props }) {
    props['data-badge'] = props.badge
    delete props.badge
    setClass(props, 'mdl-badge', true)
  },
  active ({ props }) {
    if (props.active) {
      setClass(props, 'is-active', true)
    }
  },
  shadow ({ props }) {
    const d = parseFloat(props.shadow) | 0
    const c = getClass(props).replace(/\smdl-[^ ]+--shadow\b/g, '')
    setClass(props, `${c + (c ? ' ' : '')}mdl-shadow--${d}dp`)
  }
}

export class MaterialComponent extends Component {
  component = 'none'
  js = false
  ripple = false
  mdlClasses = null
  upgradedBase = null

  mdlRender (props) {
    return <div {...props}>{props.children}</div>
  }

  render (props, state) {
    const r = this.mdlRender(props, state)
    if (this.nodeName) r.type = this.nodeName
    if (!r.props) r.props = {}
    r.props.class = this.createMdlClasses(props)
      .concat(r.props.class || [], r.props.className || [])
      .join(' ')
    for (const i in propMaps) {
      if (Object.hasOwnProperty.call(propMaps, i)) {
        if (Object.hasOwnProperty.call(props, i)) {
          propMaps[i](r)
        }
      }
    }
    if (this.base && this.upgradedBase) {
      this.preserveMdlDom(this.base, r)
    }
    return r
  }

  // Copy some transient properties back out of the DOM into VDOM prior to diffing so they don't get overwritten
  preserveMdlDom (base, r) {
    if (!base || !base.hasAttribute || !r) return

    const /* c = base.childNodes, */
      persist = [
        'mdl-js-ripple-effect--ignore-events',
        'mdl-js-ripple-effect',
        'is-upgraded',
        'is-dirty'
      ]
    const v = base.getAttribute('data-upgraded')
    let a = r.props
    let cl = getClass(a) || '' /* ,
      foundRipple = false */

    if (!a) a = {}

    if (v) {
      a['data-upgraded'] = v
      upgradeQueue.add(base)
    }

    if (base.hasAttribute('ink-enabled')) {
      if (!r.props) r.props = {}
      r.props['ink-enabled'] = 'true'
    }

    for (let i = 0; i < persist.length; i++) {
      if (base.classList.contains(persist[i])) {
        if (typeof a.class === 'string') {
          if (cl.indexOf(persist[i]) === -1) {
            cl += ` ${persist[i]}`
          }
        } else {
          ;(cl || (cl = {}))[persist[i]] = true
        }
      }
    }

    setClass(a, cl)
  }

  createMdlClasses (props) {
    const name = this.component
    const c = []
    const mapping = this.propClassMapping || {}
    const js = props.js !== false && (this.js || this.ripple)
    if (name) c.push(name)
    if (this.mdlClasses) c.push(...this.mdlClasses)
    if (this.ripple && props.ripple !== false) {
      c.push(RIPPLE_CLASS)
    }
    if (js) c.push(`js-${name}`)
    for (const i in props) {
      if (Object.hasOwnProperty.call(props, i) && props[i] === true) {
        c.push(MDL_NO_PREFIX[i] ? i : mapping[i] || `${name}--${i}`)
      }
    }
    return c.map(MDL_PREFIX)
  }

  componentDidMount () {
    if (this.base !== this.upgradedBase) {
      if (this.upgradedBase) {
        mdl().downgradeElements(this.upgradedBase)
      }
      this.upgradedBase = null
      if (this.base && this.base.parentElement) {
        this.upgradedBase = this.base
        mdl()?.upgradeElement(this.base)
      }
    }
  }

  componentWillUnmount () {
    if (this.upgradedBase) {
      mdl()?.downgradeElements(this.upgradedBase)
      this.upgradedBase = null
    }
  }
}

const upgradeQueue = {
  items: [],
  add (base) {
    if (upgradeQueue.items.push(base) === 1) {
      requestAnimationFrame(upgradeQueue.process) // eslint-disable-line no-undef
      // setTimeout(upgradeQueue.process, 1);
    }
  },
  process () {
    const p = upgradeQueue.items
    for (let i = p.length; i--;) {
      const el = p[i]
      const v = el.getAttribute('data-upgraded')
      const u = v && v.split(',')
      if (!u) continue
      for (let j = u.length; j--;) {
        const c = u[j]
        const a = c && el[c]
        if (a) {
          if (a.updateClasses_) {
            a.updateClasses_()
          }
          if (
            a.onFocus_ &&
            a.input_ &&
            a.input_.matches &&
            a.input_.matches(':focus')
          ) {
            a.onFocus_()
          }
        }
      }
    }
    p.length = 0
  }
}

/** Material Icon */
export class Icon extends MaterialComponent {
  mdlRender (props) {
    let c = getClass(props) || ''
    const icon = String(props.icon || props.children).replace(/[ -]/g, '_')
    delete props.icon
    delete props.className
    if (typeof c === 'string') {
      c = `material-icons ${c}`
    } else {
      c['material-icons'] = true
    }
    return (
      <i {...props} class={c}>
        {icon}
      </i>
    )
  }
}

/**
 *  @param primary = false
 *  @param accent = false
 *  @param colored = false
 *  @param raised = false
 *  @param icon = false
 *  @param fab = false
 *  @param mini-fab = false
 *  @param disabled = false
 */
export class Button extends MaterialComponent {
  component = 'button'
  nodeName = 'button'
  js = true
  ripple = true
}

/** Cards */

export class Card extends MaterialComponent {
  component = 'card'
}

export class CardTitle extends MaterialComponent {
  component = 'card__title'
  propClassMapping = {
    expand: 'card--expand'
  }
}

export class CardTitleText extends MaterialComponent {
  component = 'card__title-text'
  nodeName = 'h2'
}

export class CardMedia extends MaterialComponent {
  component = 'card__media'
}

export class CardText extends MaterialComponent {
  component = 'card__supporting-text'
}

export class CardActions extends MaterialComponent {
  component = 'card__actions'
  // mdlClasses = ['card--border'];
}

export class CardMenu extends MaterialComponent {
  component = 'card__menu'
}

extend(Card, {
  Title: CardTitle,
  TitleText: CardTitleText,
  Media: CardMedia,
  Text: CardText,
  Actions: CardActions,
  Menu: CardMenu
})

/** Dialogs */

export class Dialog extends MaterialComponent {
  component = 'dialog'
  nodeName = 'dialog'
  show = () => {
    this.base.show()
  }

  showModal = () => {
    this.base.showModal()
  }

  close = () => {
    this.base.close && this.base.close()
  }
}

export class DialogTitle extends MaterialComponent {
  component = 'dialog__title'
}

export class DialogContent extends MaterialComponent {
  component = 'dialog__content'
}

export class DialogActions extends MaterialComponent {
  component = 'dialog__actions'
}

extend(Dialog, {
  Title: DialogTitle,
  Content: DialogContent,
  Actions: DialogActions
})

/** Layouts */

/** @param fixed-header = false
 *  @param fixed-drawer = false
 *  @param overlay-drawer-button = false
 *  @param fixed-tabs = false
 */
export class Layout extends MaterialComponent {
  component = 'layout'
  js = true
}

/** @param waterfall = false
 *  @param scroll = false
 */
export class LayoutHeader extends MaterialComponent {
  component = 'layout__header'
  nodeName = 'header'
}

export class LayoutHeaderRow extends MaterialComponent {
  component = 'layout__header-row'
}

export class LayoutTitle extends MaterialComponent {
  component = 'layout-title'
  nodeName = 'span'
}

export class LayoutSpacer extends MaterialComponent {
  component = 'layout-spacer'
}

export class LayoutDrawer extends MaterialComponent {
  component = 'layout__drawer'
}

export class LayoutContent extends MaterialComponent {
  component = 'layout__content'
  nodeName = 'main'
}

export class LayoutTabBar extends MaterialComponent {
  component = 'layout__tab-bar'
  js = true
  ripple = false
}

/** @param active */
export class LayoutTab extends MaterialComponent {
  component = 'layout__tab'
  nodeName = 'a'
}

/** @param active */
export class LayoutTabPanel extends MaterialComponent {
  component = 'layout__tab-panel'

  mdlRender (props) {
    return (
      <section {...props}>
        <div class='page-content'>{props.children}</div>
      </section>
    )
  }
}

extend(Layout, {
  Header: LayoutHeader,
  HeaderRow: LayoutHeaderRow,
  Title: LayoutTitle,
  Spacer: LayoutSpacer,
  Drawer: LayoutDrawer,
  Content: LayoutContent,
  TabBar: LayoutTabBar,
  Tab: LayoutTab,
  TabPanel: LayoutTabPanel
})

/** @param large-screen-only = false */
export class Navigation extends MaterialComponent {
  component = 'navigation'
  nodeName = 'nav'
  propClassMapping = {
    'large-screen-only': 'layout--large-screen-only'
  }

  mdlRender (props, state) {
    const r = super.mdlRender(props, state)
    const children = Array.isArray(r.props.children)
      ? r.props.children
      : [r.props.children]
    children?.forEach(item => {
      if (!item) return item
      const c = (item.props && getClass(item.props)) || ''
      if (!c.match(/\bmdl-navigation__link\b/g)) {
        if (!item.props) item.props = {}
        setClass(item.props, ' mdl-navigation__link', true)
      }
    })
    return r
  }
}

export class NavigationLink extends MaterialComponent {
  component = 'navigation__link'
  nodeName = 'a'

  constructor (...args) {
    super(...args)
    this.handleClick = this.onHandleClick.bind(this)
  }

  onHandleClick (e) {
    const { route, href } = this.props
    if (typeof route === 'function') {
      route(href)
    }
    e.preventDefault()
    return false
  }

  mdlRender ({ children, ...props }) {
    return (
      <a {...props} onclick={props.onClick || this.onHandleClick}>
        {children}
      </a>
    )
  }
}

Navigation.Link = NavigationLink

export class Tabs extends MaterialComponent {
  component = 'tabs'
  js = true
  ripple = false
}

export class TabBar extends MaterialComponent {
  component = 'tabs__tab-bar'
}

export class Tab extends MaterialComponent {
  component = 'tabs__tab'
  nodeName = 'a'
}

export class TabPanel extends MaterialComponent {
  component = 'tabs__panel'
  nodeName = 'section'
}

extend(Tabs, {
  TabBar,
  Bar: TabBar,
  Tab,
  TabPanel,
  Panel: TabPanel
})

export class MegaFooter extends MaterialComponent {
  component = 'mega-footer'
  nodeName = 'footer'
}

export class MegaFooterMiddleSection extends MaterialComponent {
  component = 'mega-footer__middle-section'
}

export class MegaFooterDropDownSection extends MaterialComponent {
  component = 'mega-footer__drop-down-section'
}

export class MegaFooterHeading extends MaterialComponent {
  component = 'mega-footer__heading'
  nodeName = 'h1'
}

export class MegaFooterLinkList extends MaterialComponent {
  component = 'mega-footer__link-list'
  nodeName = 'ul'
}

export class MegaFooterBottomSection extends MaterialComponent {
  component = 'mega-footer__bottom-section'
}

extend(MegaFooter, {
  MiddleSection: MegaFooterMiddleSection,
  DropDownSection: MegaFooterDropDownSection,
  Heading: MegaFooterHeading,
  LinkList: MegaFooterLinkList,
  BottomSection: MegaFooterBottomSection
})

export class MiniFooter extends MaterialComponent {
  component = 'mini-footer'
  nodeName = 'footer'
}

export class MiniFooterLeftSection extends MaterialComponent {
  component = 'mini-footer__left-section'
}

export class MiniFooterLinkList extends MaterialComponent {
  component = 'mini-footer__link-list'
  nodeName = 'ul'
}

extend(MiniFooter, {
  LeftSection: MiniFooterLeftSection,
  LinkList: MiniFooterLinkList
})

/** Responsive Grid
 *  @param no-spacing = false
 */
export class Grid extends MaterialComponent {
  component = 'grid'
}

export class Cell extends MaterialComponent {
  component = 'cell'
}

Grid.Cell = Cell

/** @param indeterminate = false */
export class Progress extends MaterialComponent {
  component = 'progress'
  js = true

  mdlRender (props) {
    return (
      <div {...props}>
        <div class='progressbar bar bar1' />
        <div class='bufferbar bar bar2' />
        <div class='auxbar bar bar3' />
      </div>
    )
  }

  componentDidUpdate () {
    const api = this.base.MaterialProgress
    const p = this.props
    if (p.progress) api.setProgress(p.progress)
    if (p.buffer) api.setBuffer(p.buffer)
  }
}

/** @param active = false
 *  @param single-color = false
 */
export class Spinner extends MaterialComponent {
  component = 'spinner'
  js = true
  // shouldComponentUpdate = () => false;
}

/** @param bottom-left = true
 *  @param bottom-right = false
 *  @param top-left = false
 *  @param top-right = false
 */
export class Menu extends MaterialComponent {
  component = 'menu'
  nodeName = 'ul'
  js = true
  ripple = true
}

/** @param disabled = false */
export class MenuItem extends MaterialComponent {
  component = 'menu__item'
  nodeName = 'li'
}

Menu.Item = MenuItem

/** @param min = 0
 *  @param max = 100
 *  @param value = 0
 *  @param tabindex = 0
 *  @param disabled = false
 */
export class Slider extends MaterialComponent {
  component = 'slider'
  js = true

  mdlRender (props) {
    return <input type='range' tabindex='0' {...props} />
  }
}

/** Snackbar
 */

export class Snackbar extends MaterialComponent {
  component = 'snackbar'
  js = true

  mdlRender (props) {
    return (
      <div {...props}>
        <div class='mdl-snackbar__text'>{props.children}</div>
        <button class='mdl-snackbar__action' type='button' />
      </div>
    )
  }
}

/** @param checked = false
 *  @param disabled = false
 */
export class CheckBox extends MaterialComponent {
  component = 'checkbox'
  js = true
  ripple = true

  getValue () {
    const checked = this.base.children[0].checked
    const rc = {
      value: checked,
      message: this.props.required && !checked ? 'This field is required' : ''
    }
    return rc
  }

  mdlRender (props) {
    const evt = {}
    for (const i in props) {
      if (i.match(/^on[a-z]+$/gi)) {
        evt[i] = props[i]
        delete props[i]
      }
    }
    return (
      <label {...props}>
        <input
          type='checkbox'
          class='mdl-checkbox__input'
          checked={props.checked}
          disabled={props.disabled}
          name={props.name}
          required={props.required}
          {...evt}
        />
        <span class='mdl-checkbox__label'>{props.children}</span>
        <span class='mdl-checkbox__focus-helper' />
        <span class='mdl-checkbox__box-outline'>
          <span class='mdl-checkbox__tick-outline' />
        </span>
      </label>
    )
  }
}

/** @param name (required)
 *  @param value (required)
 *  @param checked = false
 *  @param disabled = false
 */
export class Radio extends MaterialComponent {
  component = 'radio'
  js = true
  ripple = true

  getValue () {
    return this.base.children[0].checked
  }

  mdlRender (props) {
    return (
      <label {...props}>
        <input
          type='radio'
          class='mdl-radio__button'
          name={props.name}
          value={props.value}
          checked={props.checked}
          disabled={props.disabled}
        />
        <span class='mdl-radio__label'>{props.children}</span>
      </label>
    )
  }
}

/** @param checked = false
 *  @param disabled = false
 */
export class IconToggle extends MaterialComponent {
  component = 'icon-toggle'
  js = true
  ripple = true

  getValue () {
    return this.base.children[0].checked
  }

  mdlRender (props) {
    return (
      <label {...props}>
        <input
          type='checkbox'
          class='mdl-icon-toggle__input'
          checked={props.checked}
          disabled={props.disabled}
        />
        <span class='mdl-icon-toggle__label material-icons'>
          {props.children}
        </span>
      </label>
    )
  }
}

/** @param checked = false
 *  @param disabled = false
 */
export class Switch extends MaterialComponent {
  component = 'switch'
  nodeName = 'label'
  js = true
  ripple = true

  shouldComponentUpdate ({ checked }) {
    if (Boolean(checked) === Boolean(this.props.checked)) return false
    return true
  }

  getValue () {
    return this.base.children[0].checked
  }

  mdlRender ({ ...props }) {
    const evt = {}
    for (const i in props) {
      if (i.match(/^on[a-z]+$/gi)) {
        evt[i] = props[i]
        delete props[i]
      }
    }
    return (
      <label {...props}>
        <input
          type='checkbox'
          class='mdl-switch__input'
          checked={props.checked}
          disabled={props.disabled}
          {...evt}
        />
        <span class='mdl-switch__label'>{props.children}</span>
        <div class='mdl-switch__track' />
        <div class='mdl-switch__thumb'>
          <span class='mdl-switch__focus-helper' />
        </div>
      </label>
    )
  }
}

/** @param selectable = false */
export class Table extends MaterialComponent {
  component = 'data-table'
  nodeName = 'table'
  js = true
}

/** @param non-numeric = false */
export class TableCell extends MaterialComponent {
  component = 'data-table__cell'
  nodeName = 'td'
}

Table.Cell = TableCell

export class List extends MaterialComponent {
  component = 'list'
  nodeName = 'ul'
}

/** @param two-line = false
 *  @param three-line = false
 */
export class ListItem extends MaterialComponent {
  component = 'list__item'
  nodeName = 'li'
}

export class ListItemPrimaryContent extends MaterialComponent {
  component = 'list__item-primary-content'
  nodeName = 'span'
}

export class ListItemSubTitle extends MaterialComponent {
  component = 'list__item-sub-title'
  nodeName = 'span'
}

export class ListItemTextBody extends MaterialComponent {
  component = 'list__item-text-body'
  nodeName = 'span'
}

export class ListItemSecondaryContent extends MaterialComponent {
  component = 'list__item-secondary-content'
  nodeName = 'span'
}

export class ListItemSecondaryAction extends MaterialComponent {
  component = 'list__item-secondary-action'
  nodeName = 'a'
}

List.Item = ListItem
List.ItemPrimaryContent = ListItemPrimaryContent
List.ItemSubTitle = ListItemSubTitle
List.ItemTextBody = ListItemTextBody
List.ItemSecondaryContent = ListItemSecondaryContent
List.ItemSecondaryAction = ListItemSecondaryAction

/** @param floating-label = false
 *  @param multiline = false
 *  @param expandable = false
 *  @param errorMessage = null
 *  @param icon (used with expandable)
 */
export class TextField extends MaterialComponent {
  component = 'textfield'
  js = true

  _input

  constructor (...args) {
    super(...args)
    this.id = uid()
    this.customValidations = {}
    this.didUpdate = false
  }

  getInput () {
    if (this._input === undefined) {
      this._input =
        this.base &&
        this.base.querySelector &&
        this.base.querySelector('input,textarea')
    }
    return this._input
  }

  getCustomValidationError (value) {
    if (!value) {
      return null
    }
    for (const validation in this.customValidations) {
      const [func, arg] = this.customValidations[validation]
      if (!func(value, arg)) {
        return func.errorMessage
      }
    }
    return null
  }

  getValue () {
    this.base.MaterialTextfield.doNotValidate = !this.didUpdate
    this.base.MaterialTextfield.checkValidity()

    const input = this.getInput()
    const value =
      this.props.type === 'file'
        ? input.files[0]
        : this.props.type === 'number' && input.value
          ? Number(input.value)
          : input.value
    const _customError = this.getCustomValidationError(value)
    if (_customError) {
      this.setCustomError(_customError)
    }

    const message = this.base.MaterialTextfield.currentErrorMessage

    return { value, message }
  }

  setCustomError (message) {
    this.base.MaterialTextfield.setErrorMessage(message)
  }

  componentDidUpdate () {
    const input = this.getInput()

    if (input && input.value !== this.props.value) {
      input.value =
        this.props.value === undefined || this.props.value === null
          ? ''
          : this.props.value
    }

    this.getValue()
    this.didUpdate = true
  }

  shouldComponentUpdate () {
    return this.props.type !== 'file'
  }

  mdlRender (props = {}) {
    const id = props.id || this.id
    /* errorMessage = props.errorMessage, */
    const helpText = props['help-text']
    this.customValidations = props.customValidations
    const p = extend({}, props)

    delete p.class
    delete p.errorMessage
    delete p['help-text']
    delete p.customValidations

    if (p.type === 'file') {
      p.placeholder = props.value || props.label
    }

    const input_ = (
      <input
        type='text'
        class='mdl-textfield__input'
        id={id}
        value={props.value}
        {...p}
      />
    )

    let field = (
      <div className={`field-type-${props.type}`}>
        {input_}
        <label class='mdl-textfield__label' for={id}>
          {props.label || props.children}
          {props.required && <super class='mdl-color-text--red'> *</super>}
        </label>
        <span class='mdl-textfield__error' />
        {helpText && (
          <span class='mdl-textfield__help'>
            <i class='material-icons'>info</i> {helpText}
          </span>
        )}
      </div>
    )

    if (props.multiline) {
      input_.type = 'textarea'
      // field.children[0].children = [props.value];
    }
    if (props.expandable === true) {
      ;(field.props = field.props || {}).class =
        'mdl-textfield__expandable-holder'
      field = (
        <div>
          <label class='mdl-button mdl-js-button mdl-button--icon' for={id}>
            <i class='material-icons'>{props.icon}</i>
          </label>
          {field}
        </div>
      )
    }
    const cl = getClass(props)
    if (cl) {
      ;(field.props = field.props || {}).class = cl
    }

    return field
  }
}

/** @param for [id]
 *  @param large = false
 */
export class Tooltip extends MaterialComponent {
  component = 'tooltip'
}

export class Chip extends MaterialComponent {
  component = 'chip'
  nodeName = 'span'

  mdlRender (props) {
    return (
      <span class={props.contact && 'mdl-chip--contact'}>
        {props.contact && (
          <span class={`mdl-chip__contact ${props['contact-class']}`}>
            {props.contact}
          </span>
        )}
        <span class='mdl-chip__text'>{props.text}</span>
      </span>
    )
  }
}

export class Stepper extends MaterialComponent {
  component = 'stepper'
  nodeName = 'ul'
  js = true

  mdlRender (props) {
    const total = props.children.length
    for (let i = 0; i < total; i++) {
      props.children[i].props.stepInfo = {
        stepper: this,
        isLast: i === total - 1,
        customSubmitLabel: props.customSubmitLabel,
        underReview: props.underReview,
        readonly: props.readonly,
        canGoBack: i > 0 && (!props.linear || props.readonly),
        loading: props.loading
      }
    }
    return super.mdlRender(props)
  }

  componentDidMount () {
    super.componentDidMount()
    this.props.onComponentMounted &&
      this.props.onComponentMounted(this.base.MaterialStepper)
  }

  goto = index => {
    if (!index) {
      index = this.upgradedBase.MaterialStepper.Steps_.active
    }
    this.upgradedBase.MaterialStepper.goto(index)
  }

  back = () => {
    this.upgradedBase.MaterialStepper.back()
  }

  next = () => {
    this.upgradedBase.MaterialStepper.next()
  }

  skip = () => {
    this.upgradedBase.MaterialStepper.skip()
  }

  error = (msg, index) => {
    this.upgradedBase.MaterialStepper.error(msg, index)
  }
}

export class StepperStep extends MaterialComponent {
  component = 'step'
  nodeName = 'li'

  callbacks = {}

  stepper = null
  info = {}

  handleOnContinue = () => {
    if (this.callbacks.onContinue) {
      this.callbacks.onContinue(this.info.isLast)
    } else {
      this.stepper.next()
    }
  }

  handleOnBack = () => {
    let rc = true
    if (this.callbacks.onBack) {
      rc = this.callbacks.onBack()
    }

    if (rc) {
      this.stepper.back()
    }
  }

  handleOnSkip = () => {
    let rc = true
    if (this.callbacks.onSkip) {
      rc = this.callbacks.onSkip(this.info.isLast)
    }

    if (rc) {
      this.stepper.skip()
    }
  }

  handleOnSave = () => {
    if (this.callbacks.onSave) {
      this.callbacks.onSave()
    }
  }

  error = msg => {
    this.stepper.error(msg)
  }

  mdlRender (props) {
    this.info = props.stepInfo
    this.stepper = this.info.stepper
    delete props.stepInfo

    for (const cb of ['onContinue', 'onBack', 'onSkip', 'onSave']) {
      if (props[cb]) {
        this.callbacks[cb] = props[cb]
        delete props[cb]
      }
    }

    const continueLabel = !this.info.isLast
      ? 'Continue'
      : this.info.readonly
        ? this.info.underReview
          ? 'Next form'
          : 'Close'
        : this.info.underReview
          ? this.info.customSubmitLabel
          : 'NEXT'

    const submissionIsFinal = props?.submissionFinal

    return (
      <div>
        <span class='mdl-step__label'>
          <span class='mdl-step__title'>
            <span class='mdl-step__title-text'>{props['title-text']}</span>
            <span class='mdl-step__title-message'>
              {props['title-message']}
            </span>
          </span>
        </span>
        <div class='mdl-step__content'>{props.children}</div>
        <div class='mdl-step__actions'>
          {this.info.loading && (
            <div style='position:absolute;left:50%;transform:translate(-50%, 0)'>
              <Spinner active single-color />
            </div>
          )}

          <Button
            raised
            accent
            data-stepper-back
            onClick={this.handleOnBack}
            disabled={this.info.loading || !this.info.canGoBack}
          >
            Back
          </Button>

          {props.optional && (
            <Button
              data-stepper-skip
              onClick={this.handleOnSkip}
              disabled={this.info.loading}
            >
              Skip
            </Button>
          )}
          {!this.info.underReview && !submissionIsFinal && (
            <Button
              raised
              onClick={this.handleOnSave}
              disabled={this.info.loading || this.info.readonly}
            >
              Save draft
            </Button>
          )}

          <Button
            raised
            primary
            onClick={this.handleOnContinue}
            disabled={this.info.loading}
          >
            {continueLabel}
          </Button>
        </div>
      </div>
    )
  }
}

Stepper.Step = StepperStep

export default {
  options,
  Icon,
  Button,
  Card,
  Chip,
  Dialog,
  Layout,
  Navigation,
  Tabs,
  MegaFooter,
  MiniFooter,
  Grid,
  Cell,
  Progress,
  Spinner,
  Menu,
  Slider,
  Snackbar,
  CheckBox,
  Radio,
  IconToggle,
  Switch,
  Table,
  TextField,
  Tooltip,
  List,
  ListItem,
  Stepper
}
