import axios from 'axios'
import { storageLoad, storageSave } from './storage'
import { Component } from 'preact'
import { showConfirmationDialog } from './ConfirmationDialog'

class FormBuilderClient extends Component {
  baseEndpoint = FORM_BUILDER_URL // eslint-disable-line no-undef
  authentication = {}
  user = null

  _init () {
    this._getAuthentication()
  }

  _getAuthentication () {
    this.authentication = storageLoad('authentication')
  }

  _getActiveAccess () {
    let accessToken
    const availableTokens = this.authentication?.accessTokens || []
    for (const token of availableTokens) {
      if (new Date(token.expiresAt).valueOf() > Date.now()) {
        accessToken = token
        break
      }
    }
    return accessToken
  }

  _getRefresh () {
    return this.authentication &&
      new Date(this.authentication.expiresAt).valueOf() > Date.now()
      ? this.authentication?.refreshToken
      : null
  }

  _setAuthentication (data, user) {
    this.authentication = data
    storageSave('authentication', this.authentication)
  }

  _getHeaders () {
    const headers = {
      'content-type': 'application/json'
    }
    const accessToken = this._getActiveAccess()
    if (accessToken) {
      headers.authorization = `Bearer ${accessToken.token}`
    }
    return headers
  }

  async _get (endpoint, parameters, headers) {
    return axios.get(`${this.baseEndpoint}${endpoint}`, {
      parameters,
      headers: headers || this._getHeaders()
    })
  }

  async _post (endpoint, data, headers) {
    return axios.post(`${this.baseEndpoint}${endpoint}`, data, {
      headers: headers || this._getHeaders(),
      onUploadProgress: progressEvent => {
        // Calculate the upload progress percentage
        const progress = (progressEvent.loaded / progressEvent.total) * 100

        // You can do something with the progress value here, such as updating a progress bar
        console.log(`Upload progress for file: ${progress}%`)
      }
    })
  }

  async login (username, password, authContext) {
    const data = {
      username,
      password
    }

    try {
      const rc = await this._post('/auth/login', data)
      this._setAuthentication(rc.data)
      authContext.setAuth(rc.data)
      this.user = await this.getUser(authContext)
      if (this.user.status === 'inactive') {
        const activationData = await this.requestActivation()
        console.log('Activation data:', activationData)
      }
    } catch (error) {
      throw error.response.data
    }
    return true
  }

  async refresh (token, authContext) {
    const data = { token }
    try {
      const rc = await this._post('/auth/refresh', data)
      this._setAuthentication(rc.data)
      authContext.setAuth(rc.data)
      this.user = await this.getUser(authContext)
    } catch (error) {
      throw error.response.data
    }
    return true
  }

  async logout (authContext) {
    try {
      if (this.isLoggedIn) {
        await this._post('/auth/logout')
      }
    } finally {
      this._setAuthentication({})
      authContext.setAuth({})
      this.user = null
    }
    return true
  }

  async register (data) {
    try {
      const rc = await this._post('/auth/register', data)
      return rc.data
    } catch (error) {
      throw error.response.data
    }
  }

  async requestPasswordChange (email) {
    try {
      const rc = await this._post('/auth/request-password-change', { email })
      return rc.data
    } catch (error) {
      throw error.response.data
    }
  }

  async confirmCode (code, newValue) {
    try {
      const data = {
        code,
        newValue
      }

      const rc = await this._post('/auth/verify', data)
      return rc.data
    } catch (error) {
      throw error.response.data
    }
  }

  isLoggedIn (authContext) {
    let token = this._getActiveAccess()
    if (!token) {
      token = this._getRefresh()
      if (token) {
        this.refresh(token, authContext)
        token = this._getActiveAccess()
      }
    }
    const rc = !!token

    return rc
  }

  async getUser () {
    try {
      const rc = await this._get('/user')
      return rc.data
    } catch (error) {
      throw error.response.data
    }
  }

  async requestActivation () {
    try {
      const rc = await this._post('/user/request-activation')
      return rc.data
    } catch (error) {
      throw error.response.data
    }
  }

  async getForms () {
    try {
      const rc = await this._get('/forms')
      return rc.data
    } catch (error) {
      throw error.response.data
    }
  }

  async upload (title, content, _cb) {
    try {
      const chunkSize = 1024 * 200

      const totalChunks = Math.ceil(content.length / chunkSize)
      const totalProgress = totalChunks + 2

      const payload = { title, total_chunks: totalChunks }

      let rc = await this._post('/file/new', payload)
      const fileId = rc.data.fileId

      if (!fileId) {
        throw Error({ response: { data: 'Failed to upload file' } })
      }

      _cb && _cb(1 / totalProgress)

      for (let i = 0, o = 0; i < totalChunks; ++i, o += chunkSize) {
        const chunk = content.substr(o, chunkSize)
        rc = await this._post('/file/chunk', { fileId, chunk })
        _cb && _cb((1 + i) / totalProgress)
      }

      rc = await this._post('/file/upload', { fileId })
      _cb && _cb(100)

      return rc.data
    } catch (error) {
      console.log(error)
      throw error.response.data
    }
  }

  async submit (data) {
    // Await the user's decision from the confirmation dialog
    if (data.form === '64afcc90d4e0c1f5e4b48675') {
      const result = await showConfirmationDialog()
      if (result) {
        try {
          const rc = await this._post('/forms/submit', data)
          return rc.data
        } catch (error) {
          throw error.response.data
        }
      }
    }
    if (data.form !== '64afcc90d4e0c1f5e4b48675') {
      try {
        const rc = await this._post('/forms/submit', data)
        return rc.data
      } catch (error) {
        throw error.response.data
      }
    }
  }

  async saveDraft (data) {
    try {
      const rc = await this._post('/forms/save-draft', data)
      return rc.data
    } catch (error) {
      throw error.response.data
    }
  }

  async saveCase (data) {
    try {
      const rc = await this._post('/cases', data)
      return rc.data
    } catch (error) {
      throw error.response.data
    }
  }

  async changePassword (data) {
    try {
      const rc = await this._post('/user/change-password', data)
      return rc.data
    } catch (error) {
      throw error.response.data
    }
  }

  async setShowInstructions (data) {
    try {
      const rc = await this._post('/user/set-show-instructions', data)
      return rc.data
    } catch (error) {
      throw error.response.data
    }
  }
}

const formBuilderClient = new FormBuilderClient()
formBuilderClient._init()

export default formBuilderClient
