import { route } from 'preact-router'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'

import { AuthContext } from '../../contexts'
import { Button, Card, Chip, Grid, Icon, TextField } from '../../components/mdl'

import formBuilderClient from '../../components/form-builder'
import Anonymous from '../../components/anonymous'

const MODE_FORGOT_PASSWORD = 'forgot-password'
const MODE_SUCCESS = 'success'

export default function SetPaswordRequest (props) {
  const authContext = useContext(AuthContext)

  if (formBuilderClient.isLoggedIn(authContext)) {
    route('/')
    return null
  }

  const [error, setError] = useState({})

  const [mode, setMode] = useState(MODE_FORGOT_PASSWORD)

  const formRef = {
    email: useRef()
  }

  const formData = useState({
    email: undefined
  })

  const onSubmit = () => {
    const curValue = formRef.email.current.getValue()

    if (!curValue.message) {
      formData.email = curValue.value
      formBuilderClient
        .requestPasswordChange(formData.email)
        .then(rc => {
          setMode(MODE_SUCCESS)
        })
        .catch(err => {
          setError(err)
        })
    }
  }

  const onBackToLogin = () => {
    route('/login')
  }

  const handleFormKeypress = e => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  useEffect(() => {
    if (error.field) {
      formRef[error.field].current.setCustomError(error.message)
    }
  }, [error])

  return (
    <Anonymous>
      <Card class='auth-card'>
        <Card.Title class='mdl-card--border'>
          <Card.TitleText>Forgot your password?</Card.TitleText>
        </Card.Title>
        <Card.Text class='mdl-card--border'>
          {(mode === MODE_FORGOT_PASSWORD && (
            <Grid class='form--1-col'>
              <Grid.Cell>
                <TextField
                  ref={formRef.email}
                  required
                  floating-label
                  label='Email'
                  type='email'
                  name='email'
                  value={formData.email}
                  help-text='Requires a verified email address.'
                  onKeyPress={e => handleFormKeypress(e)}
                />
              </Grid.Cell>
            </Grid>
          )) || (
            <span class='mdl-typography--subhead'>
              Your request has been accepted. You should receive an email with
              a link to setup a new password in the next few minutes.
            </span>
          )}
          {error.message && !error.field && (
            <Chip
              contact-class='mdl-color--accent mdl-color-text--white'
              contact={<Icon icon='error-outline' />}
              text={error.message}
            />
          )}
        </Card.Text>
        <Card.Actions class='mdl-dialog__actions'>
          {mode !== MODE_SUCCESS && (
            <Button raised primary onClick={onSubmit}>
              Reset password
            </Button>
          )}
          <div class='mdl-layout-spacer' />
          <Button accent onClick={onBackToLogin}>
            Back to Login
          </Button>
        </Card.Actions>
      </Card>
      <div class='auth-text'>
        Not registered yet?&nbsp;
        <a href='/register'>Register now!</a>
      </div>
    </Anonymous>
  )
}
