import { useEffect, useRef, useState } from 'preact/hooks'

import passwordSecurity from '../../validations/password.js'

import {
  Button,
  Card,
  Chip,
  Grid,
  Icon,
  TextField
} from '../../components/mdl.js'

import Anonymous from '../../components/anonymous.js'
import formBuilderClient from '../../components/form-builder.js'
import { route } from 'preact-router'

const MODE_SET_PASSWORD = 'set-password'
const MODE_SUCCESS = 'success'
const MODE_CODE_MISSING = 'code-missing'

export default function SetPassword (props) {
  const [mode, setMode] = useState(
    (props.code && MODE_SET_PASSWORD) || MODE_CODE_MISSING
  )
  const [error, setError] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [formData, setFormData] = useState({
    code: props.code,
    password: undefined,
    password_confirmation: undefined
  })

  const passwordCriteriaMatch = {
    criteria_1: {
      label: 'must be between 8 and 64 characters long',
      matches: (password) => {
        return passwordSecurity.meetsLength(password, 8, 64)
      }
    },
    criteria_2: {
      label: 'must contain at least 1 upper case letter (A..Z)',
      matches: (password) => {
        return passwordSecurity.meetsUpperCase(password, 1)
      }
    },
    criteria_3: {
      label: 'must contain at least 1 lower case letter (a..z)',
      matches: (password) => {
        return passwordSecurity.meetsLowerCase(password, 1)
      }
    },
    criteria_4: {
      label: 'must contain at least 1 number (0..9)',
      matches: (password) => {
        return passwordSecurity.meetsNumber(password, 1)
      }
    },
    criteria_5: {
      label: 'must contain at least 1 special character (!..$)',
      matches: (password) => {
        return passwordSecurity.meetsSpecial(password, 1)
      }
    }
  }

  const formRef = {
    password: useRef(),
    password_confirmation: useRef()
  }

  const onSubmit = () => {
    formData.password = formRef.password.current.getValue().value
    formData.password_confirmation =
      formRef.password_confirmation.current.getValue().value

    if (formData.password !== formData.password_confirmation) {
      formRef.password_confirmation.current.setCustomError(
        'Password confirmation does not match.'
      )
    }

    const validPassword =
      passwordSecurity.meetsLength(formData.password, 8, 64) &&
      passwordSecurity.meetsUpperCase(formData.password, 1) &&
      passwordSecurity.meetsLowerCase(formData.password, 1) &&
      passwordSecurity.meetsNumber(formData.password, 1) &&
      passwordSecurity.meetsSpecial(formData.password, 1)

    if (!validPassword) {
      formRef.password.current.setCustomError(
        "Password doesn't meet the required criteria"
      )
    }

    const allIn =
      formData.password &&
      formData.password === formData.password_confirmation &&
      validPassword

    if (allIn) {
      setFormData(formData)
      formBuilderClient
        .confirmCode(formData.code, formData.password)
        .then(rc => {
          setMode(MODE_SUCCESS)
        })
        .catch(err => {
          setError(err)
        })
    }
  }

  const handleFormKeypress = e => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const onForgotPassword = () => {
    route('/set-password/request')
  }

  const handlePasswordEntered = e => {
    const formData2 = {}
    formData2.code = props.code
    formData2.password = formRef.password.current.getValue().value
    formData2.password_confirmation = formRef.password_confirmation.current.getValue().value
    setFormData(formData2)
  }

  const togglePasswordVisibility = () => {
    // Reset the form data
    const formDataTemp = {}
    formDataTemp.code = props.code
    formDataTemp.password = formRef.password.current.getValue().value
    formDataTemp.password_confirmation = formRef.password_confirmation.current.getValue().value
    setFormData(formDataTemp)
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (error.field) {
      formRef[error.field].current.setCustomError(error.message)
    }
  }, [error])

  useEffect(() => {
    if (mode === MODE_CODE_MISSING) {
      setError({ message: 'Required code is missing! This link is not valid.' })
    }
  }, [mode])

  const passwordToggleButton = () => {
    return (
      <a
        href='#'
        className='password-show'
        onClick={togglePasswordVisibility}
      >
        <i class='material-icons'>
          {showPassword ? 'visibility' : 'visibility_off'}
        </i>
      </a>
    )
  }

  return (
    <Anonymous>
      <Card class='auth-card'>
        <Card.Title class='mdl-card--border'>
          <Card.TitleText>Set new password</Card.TitleText>
        </Card.Title>
        <Card.Text class='mdl-card--border'>
          {(mode === MODE_SET_PASSWORD && (
            <Grid class='form--1-col'>
              <Grid.Cell>
                <div className='mdl-password-wrapper'>
                  <TextField
                    ref={formRef.password}
                    required
                    floating-label
                    label='Password'
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    value={formData.password}
                    onKeyUp={e => handlePasswordEntered(e)}
                  />
                  {passwordToggleButton()}
                </div>
              </Grid.Cell>
              <Grid.Cell>
                <div className='mdl-password-wrapper'>
                  <TextField
                    ref={formRef.password_confirmation}
                    required
                    floating-label
                    label='Confirm Password'
                    type={showPassword ? 'text' : 'password'}
                    name='password_confirmation'
                    value={formData.password_confirmation}
                    onKeyPress={e => handleFormKeypress(e)}
                  />
                  {passwordToggleButton()}
                </div>
              </Grid.Cell>
              <Grid.Cell>
                <div className='password-requirement-list'>
                  <p>Password requirements:</p>
                  <ul className='pw-checklist'>
                    {Object.keys(passwordCriteriaMatch).map(criteriaKey => {
                      const criteria = passwordCriteriaMatch[criteriaKey]
                      const pw = formData.password ? formData.password : ''
                      const matches = criteria.matches(pw)

                      return (
                        <li key={criteriaKey} className={matches ? 'matches' : ''}>
                          {criteria.label}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </Grid.Cell>
            </Grid>
          )) ||
            (mode === MODE_SUCCESS && (
              <span class='mdl-typography--subhead'>
                Your password has been successfully set. You can now use it to{' '}
                <a href='/login'>Login</a>.
              </span>
            ))}
          {error.message && !error.field && (
            <Chip
              contact-class='mdl-color--accent mdl-color-text--white'
              contact={<Icon icon='error-outline' />}
              text={error.message}
            />
          )}
        </Card.Text>
        <Card.Actions class='mdl-dialog__actions'>
          {mode === MODE_SET_PASSWORD && (
            <Button raised primary onClick={onSubmit}>
              Submit
            </Button>
          )}
          <div class='mdl-layout-spacer' />
          <Button accent onClick={onForgotPassword}>
            Request a new link
          </Button>
        </Card.Actions>
      </Card>
      <div class='auth-text'>
        Remembered your password?&nbsp;
        <a href='/login'>Login</a>
      </div>
    </Anonymous>
  )
}
