import 'dialog-polyfill/dist/dialog-polyfill.css'
import 'material-icons/iconfont/material-icons.css'
import './style/material.css'
import './style/stepper.css'

import '@fontsource/pt-sans-narrow'
import './assets/fonts/gill/stylesheet.css'

import './style'

import 'dialog-polyfill'
import './components/material'

import App from './app'

export default App
